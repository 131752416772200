<template>
	<b-card no-body class="badge-path d-inline-flex flex-row align-items-center p-2 border-0 align-middle">
		<b-card-body class="d-inline-flex flex-row p-0">
			<icon-series class="mr-2" />
			<b-card-text class="badge-path__text font-weight-bold w-color-white">
				Séria
			</b-card-text>
		</b-card-body>
	</b-card>
</template>

<script>
export default {
	components: {
		'icon-series': () => import('./icon/icon-series.svg?inline')
	}
}
</script>

<style lang="scss" scoped>
.badge-path {
	max-width: 100px;
	background-color: #EB1B69;
	border-radius: 4px;

	.badge-path__text {
		font-size: 0.875rem;
	}
}
</style>